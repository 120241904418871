/* eslint-disable import/extensions */
import 'typeface-open-sans'

export default {
  /* Colors */
  colorBackground: '#FFF',
  colorPrimary: '#20699C',
  colorSecondary: '#E96012',
  colorFurniture: '#E96012',
  colorBorder: '#CCCCCC',
  colorBackgroundHue: '#EEF6FC',
  colorText: '#494949',
  colorTextReverse: '#FFFFFF',
  colorTextPlaceholder: '#9b9b9b',
  colorSuccess: '#5f9e42',
  colorError: '#d63500',
  colorWarning: '#ffc107',

  /* fonts */
  fontInterface: "'Open Sans'",
  fontHeading: "'Open Sans'",
  fontReading: "'Lora'",
  fontWriting: "'Open Sans'",

  // font sizes
  fontSizeBase: '1rem',
  fontSizeBaseSmall: '.875rem',
  fontSizeHeading1: '2rem',
  fontSizeHeading2: '1.5rem',
  fontSizeHeading3: '1.25rem',
  fontSizeHeading4: '1.125rem',
  fontSizeHeading5: '1rem',
  fontSizeHeading6: '.875rem',

  // line heights
  lineHeightBase: '1.5rem',
  lineHeightBaseSmall: '1.5rem',
  lineHeightHeading1: '2.5rem',
  lineHeightHeading2: '2rem',
  lineHeightHeading3: '1.75rem',
  lineHeightHeading4: '1.5rem',
  lineHeightHeading5: '1.5rem',
  lineHeightHeading6: '1.5rem',

  action: {
    color: '#007e92',
    colorActive: '#003049',
  },

  wizard: {
    colorBackground: '#ffffff',
  },

  appBar: {
    boxShadow: '0 2px 3px 0 rgba(25, 102, 141, 0.19)',
    colorBackground: '#ffffff',
    height: '70px',
    zIndex: 1000,
  },

  accordion: {
    headerBackgroundColor: '#ffffff',
    backgroundColor: '#ffffff',
    headerFontSize: '16px',
    headerFontFamily: "'Myriad Pro'",
    border: '1px solid #dbdbdb',
  },

  heading: {
    h1Color: '#589eb8',
    h2Color: '#007e92',
    h3Color: '#242424',
    h3LineHeight: '19px',
    h4Color: '#4F4F4F',
  },

  button: {
    borderActive: 'rgba(0,0,0,0.34)',
    borderDefault: 'rgba(0,0,0,0.14)',

    primaryText: '#f6f6f6',
    primary: '#63A945',
    disabled: '#dbdbdb',
    secondary: 'none',
    secondaryBorderWidth: '2px',
    secondaryBorderColor: '#003049',

    smallSize: '12px',
    smallHeight: 3,
    smallWidth: 10,

    mediumSize: '14px',
    mediumHeight: 4,
    mediumWidth: 12,

    largeSize: '14px',
    largeHeight: 5,
    largeWidth: 15,

    xLargeSize: '14px',
    xLargeHeight: 5,
    xLargeWidth: 24,
  },

  box: {
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    border: '1px solid #E7E7E7',
  },

  textField: {
    activeBorder: '#003049',
  },

  menu: {
    hoverColor: '#003049',
    openerShadow: '0 2px 6px -1px rgba(125, 125, 125, 0.5)',
    optionBackground: 'rgba(0, 126, 146, 0.1)',
  },

  checkbox: {
    borderColor: '#939393',
  },

  tag: {
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#586971',
    statusBackgroundColor: '#dbafc1',
    borderRadius: '2px',
  },

  dashboardCard: {
    hoverShadow: '0 1px 2px 1px #939393',
  },

  steps: {
    currentStepColor: '#003049',
    activeFontWeight: '600',
  },

  /* Text variables */

  // font weights
  fontWeightNormal: '400',
  fontWeightSemibold: '600',
  fontWeightBold: '700',

  /* Spacing */
  gridUnit: '8px',

  /* Border */
  borderRadius: '4px',
  borderWidth: '1px', // julien: not 0
  borderStyle: 'solid',

  /* Shadow (for tooltip) */
  boxShadow: '0 1px 2px 1px #dbdbdb',

  /* Transition */
  transitionDuration: '0.2s', // TODO -- julien: not 0.05s
  transitionTimingFunction: 'ease',
  transitionDelay: '0',
}
