import React from 'react'
import { Action, ActionGroup, Link } from '@pubsweet/ui'
import logo from './Navigation/pubsweet.jpg'
import './LandingPage.css'

const LandingPage = () => (
  <div className="landing-wrapper">
    <Link to="/">
      <img alt="logo" className="logo" height="50" src={logo} />
    </Link>
    <ActionGroup>
      <Action to="/login">Login</Action>
      <Action to="/signup">Signup</Action>
      <Action to="/board">Dashboard</Action>
      <Action to="/dashboard">Switchboard</Action>
    </ActionGroup>
  </div>
)

export default LandingPage
