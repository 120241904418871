import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import { hot } from 'react-hot-loader'

import { Root } from 'pubsweet-client'

import { createBrowserHistory } from 'history'
import theme from './theme'
import routes from './routes'

const history = createBrowserHistory()

const rootEl = document.getElementById('root')

ReactDOM.render(
  <Root
    connectToWebSocket={false}
    history={history}
    routes={routes}
    //  We add connectToWebSocket={false} attribute to cancel regular pubsweet subscription requests
    //  If we want to enable subscription and don't get error from it, we need to rename localStorage auth token to "token"
    theme={theme}
  />,
  rootEl,
)

export default hot(module)(Root)
